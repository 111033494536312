import React, { useEffect, useState } from "react";
import Layout from "../layouts/Layout";
import { Input } from "@chakra-ui/react";
import { graphql } from "gatsby";
import SiteMetadata from "../components/SiteMetaData";
import { FaFileDownload } from "react-icons/fa";
import { navigate } from 'gatsby'

const QUERY = `{
    downloadsCollection{
    items{
      password
    }
  }
}`;


const LoginPage = (props) => {
    const [passwordTyped, setPasswordTyped] = useState(null);
    const [passwordTrue, setPasswordTrue] = useState(null);
    const [warningCount, setWarningCount] = useState(0)
    const [fine, setFine] = useState(-1000)



    useEffect(() => {
        window
            .fetch(`https://graphql.contentful.com/content/v1/spaces/jib5q1z57yyi/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer uhFbFe4ILX5x45Al0eRvOdN0AZnIbLCKWw0OvACMsRY",
                },
                body: JSON.stringify({ query: QUERY }),
            })
            .then((response) => response.json())
            .then(({ data, errors }) => {
                if (errors) {
                    console.error(errors);
                }

                setPasswordTrue(data?.downloadsCollection?.items[0].password);
            });
    }, [passwordTyped]);

    const handleChange = (event) => {
        event.preventDefault();
        setPasswordTyped(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (passwordTrue !== null)
            props.setLoggedIn(passwordTyped === passwordTrue);
        if (passwordTrue !== passwordTyped) {
            setWarningCount(warningCount + 1)
            setFine(fine + 200)
        }
        if (warningCount === 4 && passwordTyped !== passwordTrue){return navigate('/')
        }
    };

    return (
        <>

            <p className="max-w-screen-sm mb-4">
                Please enter the correct password to get access to the download area.
            </p>
            <form className="" onSubmit={handleSubmit}>
                <Input
                    className="border-2 border-gray-200 rounded-md p-1.5 px-1.5 text-base text-gray-700"
                    type="password"
                    placeholder="Enter password"
                    onChange={handleChange}
                />
                <input
                    type="submit"
                    className="cursor-pointer bg-blue-100 bg-white border-2 border-blue-100 rounded-md p-1.5 px-2.5 text-gray-700 ml-1 hover:bg-blue-200 hover:border-blue-200 text-base transition"
                    value="Get Access"
                />
            </form>
            { 0 < warningCount && warningCount < 4 && <p className="text-red-500 text-xs pt-0.5">The password is incorrect.{warningCount===2 && " [2nd attempt]"}{warningCount===3&& " [3rd attempt]"}</p>
            }
            {warningCount === 4 && <p className="text-red-500 text-xs pt-0.5">The password is incorrect. Please contact the admin.</p>
            }
            {/* {warningCount > 4 && <p className="text-red-500 text-xs pt-0.5">{`Caution! You will be charged $200 on the next failed attempt! ${fine > 0 ? ("[$" + fine + "]") : ""}`}</p>
      } */}
        </>
    );
};

const DownloadsPage = ({ data }) => {
    const files = data.allContentfulDownloads.nodes[0].downloads;
    return (
        <>
            <div className="mb-2">List of files to download</div>
            <div className="lg:grid grid-cols-3 gap-2 inline-block lg:flex">
                {files.map((item, index) => (
                    <>
                        <a
                            href={item.file.url}
                            alt="download"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div className="bg-blue-100 rounded-md p-2.5 px-3 my-2 hover:scale-105 lg:hover:scale-102 transform transition duration-300">
                                <div className="flex items-center">
                                    <div>
                                        <p className="font-bold" key={index}>
                                            {item.title.substr(0,20)}{item.title.length >= 21 && "..."}
                                        </p>
                                        <p className="text-sm">{item.description.substr(0,29)}{item.description.length >= 30 && "..."}</p>
                                    </div>
                                    <FaFileDownload size={30} className="ml-auto" />
                                    <div className="">
                                        <p className="text-sm">
                                            .{item.file.fileName.split(".").pop()}
                                        </p>
                                        <p className="text-sm">
                                            {(item.file.details.size / 1000000).toFixed(2)} mb
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </>
                ))}
            </div>
        </>
    );
};

function Downloads(props) {
    const [loggedIn, setLoggedIn] = useState(false);

    return (
        <Layout pageContext={props.pageContext}>
            <SiteMetadata title="Downloads" />
            <div className="container">
                <div className="text-lg max-w-screen-lg 2xl:max-w-screen-xl mx-auto 2xl:mx-0 leading-relaxed">
                    <div className="text-5xl py-8 mt-6 font-medium">Downloads</div>

                    {!loggedIn && <LoginPage setLoggedIn={setLoggedIn} />}
                    {loggedIn && <DownloadsPage data={props.data} />}
                </div>
            </div>
        </Layout>
    );
}

export default Downloads;

export const query = graphql`
  query DownloadQuery {
    allContentfulDownloads {
      nodes {
        downloads {
          title
          description
          file {
            url
            fileName
            details {
              size
            }
          }
        }
      }
    }
  }
`;
